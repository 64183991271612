import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevTabViewComponent } from './tabview.component';
import { ZAppDevTabPanel } from './tabpanel.component';

const COMPONENTS = [
  zAppDevTabViewComponent,
  ZAppDevTabPanel
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule
  ],
  exports: [...COMPONENTS]
})
export class zAppDevTabContainerModule { }
