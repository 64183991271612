import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPagesService } from './pages.interface';

import * as Http from 'src/app/@framework/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import { Joove } from 'src/app/@framework/core/joove';

@Injectable({ providedIn: 'root'})

export class PagesService implements IPagesService
{
    constructor(private httpClient: HttpClient) {
    }

    refreshContext(page: string, action: string): Observable<Joove.IContext> {
        return this.httpClient
          .get(environment.appUrl + page + `/_Context_` + action, Http.zAppDevGetActionOptions)
          .pipe(map((data: any) => {
            data.body.currentUser = JSON.parse(data.body.currentUser);
            return data.body;
        }));
    }
}