import {
    AfterViewInit,
    HostBinding,
    Input,
    Directive,
    OnInit,
    OnDestroy,
  } from '@angular/core';
  
  import { zAppDevComponentSize } from '../component-size';
  import { zAppDevComponentStatus } from '../component-status';
  
  import { IThemeSevice } from '../../theme/interfaces/theme.interface';
  import * as ThemeOptions from '../../theme/interfaces/options';
  import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';
  import { zAppDevBaseComponent } from '../basecomponent/base.component';
  
  @Directive({
    selector: '[zappButton]',
    providers: [{ provide: CF_COMPONENT, useExisting: zAppDevButtonDirective }]
  })
  export class zAppDevButtonDirective implements AfterViewInit {
    protected options: ThemeOptions.ButtonThemeOptions;
  
    @Input() size: zAppDevComponentSize = 'med';
  
    @Input() status: zAppDevComponentStatus = 'global';
  
    @Input() variation: string = 'Standard';
  
    @Input() class: string = '';
  
    @HostBinding('class') hostClasses: string;
  
    constructor(protected themeservice: IThemeSevice) {
      
    }
  
    hidden: boolean = false;
  
    disabled: boolean = false;
  
    ngAfterViewInit(): void {
    }
  
    ngOnInit(): void {
      this.options = this.themeservice.getButtonThemeOptions();
  
      this.hostClasses = [
        this.class,
        this.statusClass(),
        this.sizeClass(),
      ].join(' ');
    }
  
    ngOnDestroy(): void {
  
    }
  
    statusClass() {
      const extraClasses = this.options[this.variation].Classes.Roles[this.status];
      return extraClasses;
    }
  
    sizeClass() {
      const extraClasses = this.options[this.variation].Classes.Roles[this.size];
      return extraClasses;
    }
  }
  