import { OnDestroy, Input, OnInit, Component, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { zAppDevComponentSize } from '../component-size';
import { zAppDevComponentStatus } from '../component-status';

import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';

@Component({
  selector: 'zappIcon',
  template: `<i [class]="iconClass" (click)="iconClick($event)"></i>`,
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevIconismComponent }]
})
export class zAppDevIconismComponent implements OnInit, OnDestroy {
  @Input() size: zAppDevComponentSize = '';

  @Input() status: zAppDevComponentStatus = 'global';

  @Input() variation: string = 'Standard';

  @Input() class: string = '';

  @Input() themeIcon: string = '';

  @Input() circularBackground: boolean = false;

  @Input() clickable: boolean = false;

  @Input() disabled: boolean = false;

  themeOptions: ThemeOptions.IconismThemeOptions;

  iconClass: string = '';

  @HostBinding('class')
  hostClasses: string;

  constructor(private themeService: IThemeSevice) { }

  statusClass() {
    if (this.variation in this.themeOptions.Variations) {
      return `zapp-icon ${this.themeOptions.Variations[this.variation]?.Classes?.Roles[this.status]}`;
    }
    return 'zapp-icon';
  }

  sizeClass() {
    if (this.variation in this.themeOptions.Variations) {
      return this.themeOptions.Variations[this.variation]?.Classes?.Roles[this.size];
    }
    return '';
  }

  ngOnInit(): void {
    this.themeOptions = this.themeService.getIconismThemeOptions();

    if (this.themeIcon in this.themeOptions.Icons) {
      this.iconClass = this.themeOptions.Icons[this.themeIcon];
      this.iconClass += this.circularBackground ? " round" : "";
      this.iconClass += this.clickable ? " clickable" : "";
    }

    this.hostClasses = [
      this.class,
      this.statusClass(),
      this.sizeClass(),
    ].join(' ');
  }

  iconClick(event) {
    if (this.disabled) {
      event.stopPropagation();
    }
  }

  ngOnDestroy(): void {

  }
}
