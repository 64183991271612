import { Input, HostBinding, Component, OnInit, HostListener, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { zAppDevComponentSize } from '../component-size';
import { zAppDevComponentStatus } from '../component-status';

import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import * as ThemeOptions from '../../theme/interfaces/options';
import { ValueFormat } from '../../core/clms.fw.angular';
import { safeDeepPropertySet } from '@framework/common';
import { CF_COMPONENT, IConditionalFormattingComponent } from '../../rule-engine/directives/condition-formatting.directive';
import { zAppDevBaseComponent } from '../basecomponent/base.component';

@Component({
  selector: "zappTextbox",
  templateUrl: './textbox.component.html',
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevTextBoxComponent }]
})
export class zAppDevTextBoxComponent extends zAppDevBaseComponent {
  changeTimer: any;
  protected options: ThemeOptions.TextboxThemeOptions;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  private _valueFormat: ValueFormat;
  _value: any = null;
  private defaultFormat: any = {
    'decimals': 0,
    'groups': true,
    'signed': false,
    'showOnlyDecimalPart': false,
    'dateFormat': '',
    'backEndFormatting': '#.00;\'-\'#.00;\'0\''
  };

  @Input()
  set value(value: any) {
    if (value == null) {
      this._value = null;
      return;
    }
    if (this.type != "string") {
      if (this._valueFormat != null) {
        var formatedValue = this._valueFormat.format(value);
        this._value = formatedValue;
      } else {
        this._value = value;
      }
    } else {
      this._value = value;
    }
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() name: string;

  @Input() size: zAppDevComponentSize = '';

  @Input() status: zAppDevComponentStatus = 'global';

  @Input() variation: string = 'Standard';

  @Input() class: string = '';

  @Input() type: string;

  @Input() isDecimal: boolean;

  @Input() valueFormat: any;

  @Input() readonly: boolean = false;

  @Input() required: boolean;

  @Input() placeholder: string = "";

  @Input() maxLength: number;

  @Input() liveUpdate: boolean = false;

  @Input() liveChangeDelay: number = 200;

  @ViewChild('Input')
  InputElement: any;

  hidden: boolean = false;

  disabled: boolean = false;

  constructor(protected themeservice: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
    this.options = themeservice.getTextboxThemeOptions();

  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.valueFormat != null) {
      this._valueFormat = new ValueFormat(this.valueFormat);
    } else {
      if (this.isDecimal) {
        this.defaultFormat.decimals = 2;
      }
      this._valueFormat = new ValueFormat(this.defaultFormat);
    }

    if (this._valueFormat != null && this._value != null && this._value != "") {
      //set value in order to re-run setter and apply formating
      this.value = this._value;
    }
  }

  valueChangedEvent($event) {
    this.setValue($event.target.value);
  }

  setValue(value) {
    if (this.liveUpdate) {
      return;
    }

    if (value == null || `${value}`.trim().length <= 0) {
      this.valueChange.emit(this.type == "string" ? value : null);
      return;
    }

    if (this.type != "string") {
      if (this._valueFormat != null) {
        value = this._valueFormat.unformat(value);
        this.value = +value;
        this.valueChange.emit(+value);
      } else {
        this.value = +value;
        this.valueChange.emit(+value);
      }
    } else {
      this.value = value;
      this.valueChange.emit(value);
    }
  }

  statusClass() {
    const extraClasses = this.options[this.variation].Classes.Roles[this.status];
    return extraClasses;
  }

  sizeClass() {
    const extraClasses = this.options[this.variation].Classes.Roles[this.size];
    return extraClasses;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {

    clearTimeout(this.changeTimer);
    if (this.type != "number") {
      return;
    }

    if (this.isDecimal && (e.key === ',' || e.key === '.')) {
      if (this.InputElement.nativeElement.value != null && this.InputElement.nativeElement.value.toString().indexOf(window._context.decimalSeparator) > -1) {
        e.preventDefault(); //allow only 1 decimal seperator
        return;
      } else {
        const target = this.InputElement.nativeElement;
        const start = target.selectionStart;
        const end = target.selectionEnd;
        const val = target.value;
        const value = val.slice(0, start) + window._context.decimalSeparator + val.slice(end);
        target.value = value;
        e.preventDefault();
        return;
      }
    }

    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) ||// Cmd+X (Mac)
      (e.keyCode === 189 || e.keyCode === 109) // -
    ) {
      return;  // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  onkeyup(e: KeyboardEvent) {
    if (this.liveUpdate == false) {
      return;
    }
    clearTimeout(this.changeTimer);
    let value = this.InputElement.nativeElement.value.toString();
    if (this._value == value) {
      return;
    }
    this.changeTimer = setTimeout(() => {
      if (this.type != "string") {
        if (this._valueFormat != null) {
          value = this._valueFormat.unformat(value);
          this.valueChange.emit(+value);
        } else {
          this.valueChange.emit(+value);
        }
      } else {
        this.valueChange.emit(value);
      }
    }, this.liveChangeDelay);
  }


  @HostBinding('class.textbox-readonly')
  get readonlyTextbox() {
    return this.readonly === true;
  }

  @HostBinding('class.hidden')
  get hiddenTextbox() {
    return this.hidden === true;
  }

  // override base component
  @HostBinding('class')
  get hostClasses(): string {
    return this.hostClassesArray.concat(this.styleClassesArray).join(' ');
  }

}
