import {Injectable} from "@angular/core";
import {UserProfileSettingsService} from "@services/userprofilesettings.service";

@Injectable({
  providedIn: 'root'
})
export class MenuFavorites {
  // menu -> route -> count
  // this is used to store the most used menu items in memory
  // this object should always be in sync with the profile settings
  private mostUsedItems: Map<string, Map<string, number>> = new Map();

  // menu -> routes set
  // this is used to store the favorite menu items in memory
  // this object should always be in sync with the profile settings
  private favoriteItems: Map<string, Set<string>> = new Map();

  public constructor(
    private readonly userProfileSettings: UserProfileSettingsService
  ) {
  }

  private async fetchMostUsed(menu: string) {
    const mostUsed = await this.userProfileSettings.getProfileSetting(`MenuMostUsed_${menu}`);
    const mostUsedMap = new Map<string, number>();
    if (!mostUsed) {
      this.mostUsedItems.set(menu, mostUsedMap);
      return;
    }
    const parsedObject = JSON.parse(mostUsed);
    Object.keys(parsedObject).forEach(key => {
      mostUsedMap.set(key, parsedObject[key]);
    });
    this.mostUsedItems.set(menu, mostUsedMap);
  }

  private async fetchFavorites(menu: string) {
    const favorites = await this.userProfileSettings.getProfileSetting(`MenuFavorites_${menu}`);
    const favoritesSet = new Set<string>();
    if (!favorites) {
      this.favoriteItems.set(menu, favoritesSet);
      return;
    }
    const parsedFavorites = JSON.parse(favorites);
    parsedFavorites.forEach((favorite: string) => {
      favoritesSet.add(favorite);
    });
    this.favoriteItems.set(menu, favoritesSet);
  }

  /**
   * Preloads the most used and favorite items for a menu.
   * This method must be called before doing anything else for that menu.
   */
  public async fetchMenu(menu: string) {
    await this.fetchMostUsed(menu);
    await this.fetchFavorites(menu);
  }

  /**
   * Returns the list of favorite items for a menu.
   */
  public getFavorites(menu: string): string[] {
    return Array.from(this.favoriteItems.get(menu));
  }

  /**
   * Returns the list of most used items for a menu.
   */
  public getTopMostUsed(menu: string): string[] {
    const items = this.mostUsedItems.get(menu);
    return Array.from(items.keys())
      .sort((a, b) => items.get(b) - items.get(a))
      .slice(0, 5);
  }

  /**
   * Adds a route to the list of favorites.
   */
  public async addToFavorites(menu: string, route: string) {
    // Add the favorite to the in-memory list
    this.favoriteItems.get(menu).add(route);
    // Store the favorite in the profile settings
    await this.userProfileSettings.setProfileSetting(
      `MenuFavorites_${menu}`,
      JSON.stringify(Array.from(this.favoriteItems.get(menu)))
    );
  }

  /**
   * Removes a route from the list of favorites.
   */
  public async removeFromFavorites(menu: string, route: string) {
    // Remove the favorite from the in-memory list
    this.favoriteItems.get(menu).delete(route);
    // Store the updated list in the profile settings
    await this.userProfileSettings.setProfileSetting(
      `MenuFavorites_${menu}`,
      JSON.stringify(Array.from(this.favoriteItems.get(menu)))
    );
  }

  /**
   * Increases the count of a route in the list of most used items.
   */
  public async increaseMostUsed(route: string, menu: string) {
    if (!route) {
      return;
    }
    // Set to memory
    const items = this.mostUsedItems.get(menu);
    const currentCount = items.get(route) || 0;
    items.set(route, currentCount + 1);
    // Store to profile settings
    await this.storeMostUsedMenuItems(menu);
  }

  /**
   * Removes a route from the list of most used items.
   */
  public async removeMostUsed(route: string, menu: string) {
    // Set to memory
    const items = this.mostUsedItems.get(menu);
    items.delete(route);
    // Store to profile settings
    await this.storeMostUsedMenuItems(menu);
  }

  private async storeMostUsedMenuItems(menu: string) {
    const memory = this.mostUsedItems.get(menu);
    const objectToStore = {};
    memory.forEach((value, key) => {
      objectToStore[key] = value;
    });
    const stringifiedObject = JSON.stringify(objectToStore);
    await this.userProfileSettings.setProfileSetting(`MenuMostUsed_${menu}`, stringifiedObject);
  }
}
