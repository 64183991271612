import { Directive } from '@angular/core';

@Directive({
  selector: '[zappPermissionsExcept]'
})
export class PermissionsExceptDirective {

  constructor() { }

}
