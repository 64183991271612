import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { zAppDevBaseComponent } from '../basecomponent/base.component';

export type SlidePosition = 'top' | 'right' | 'bottom' | 'left';

@Component({
  selector: 'zapp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class ZAppDevSidebarComponent extends zAppDevBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(protected elementRef: ElementRef) {
    super(elementRef);

  }

  @Input('footer')
  footerTpl: TemplateRef<any>;

  @Input('header')
  headerTpl: TemplateRef<any>;

  @Input() slidePosition: SlidePosition = 'right';

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  display: boolean = false;


  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {

  }

  public showDialog() {
    this.display = true;
  }

  public hideDialog() {
    this.display = false;
  }

  close(event) {
    this.onClose.emit();
  }

  ngOnDestroy() {

  }

}
