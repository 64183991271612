<p-sidebar [(visible)]="display" [position]="slidePosition" [style]="_style" (onHide)="close($event)" [showCloseIcon]="false">
  <ng-template pTemplate="header">
    <ng-container *ngTemplateOutlet="headerTpl">
    </ng-container>
  </ng-template>
  <ng-template pTemplate="content"><ng-content></ng-content></ng-template>
  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="footerTpl"></ng-container>
  </ng-template>
</p-sidebar>
