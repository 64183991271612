import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { zAppDevCheckBoxComponent } from "./checkbox.component";
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [zAppDevCheckBoxComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule
  ],
  exports: [zAppDevCheckBoxComponent],
})
export class zAppDevCheckBoxModule { }
