import * as ThemeOptions from '../../@framework/theme/interfaces/options';
import { IThemeSevice } from '../../@framework/theme/interfaces/theme.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LightThemeService extends IThemeSevice {
    constructor() {
        super();
    }
    
    themeOptions: ThemeOptions.ThemeOptions = {
      variables: {
        '@main-font': `'Open Sans', sans-serif`,
'@secondary-font': `'Open Sans', sans-serif`,
'@font-xs': `0.5rem`,
'@font-sm': `0.75rem`,
'@font-med': `1rem`,
'@font-lg': `1.25rem`,
'@font-xl': `1.5rem`,
'@input-height': `2rem`,
'@input-padding-h': `0.5rem`,
'@input-padding-v': `0.25rem`,
'@input-font-size': `1rem`,
'@default-radius': `0.25rem`,
'@default-shadow': `unset`,
'@default-transition': `usnet`,
'@input-border-width': `1px`,
'@bg-color': `#F0F0F7`,
'@containers-color': `#FFFFFF`,
'@border-color': `#CACED5`,
'@font-color': `#7C7B98`,
'@color-default': `#4D4F5C`,
'@color-default-hover': `#7f8ea3`,
'@color-default-contrast': `#f8f9fa`,
'@color-primary': `#6366F1`,
'@color-primary-hover': `#9294f5`,
'@color-primary-contrast': `#ffffff`,
'@color-success': `#22C55E`,
'@color-success-hover': `#1a9949`,
'@color-success-contrast': `#ffffff`,
'@color-info': `#3B82F6`,
'@color-info-hover': `#6ca1f8`,
'@color-info-contrast': `#ffffff`,
'@color-warning': `#F59E0B`,
'@color-warning-hover': `#c57f08`,
'@color-warning-contrast': `#ffffff`,
'@color-danger': `#EF4444`,
'@color-danger-hover': `#f37373`,
'@color-danger-contrast': `#ffffff`,
'@input-color': `#495057`,
'@input-bg': `#ffffff`,
'@input-border-color': `#ced4da`,
'@footer-bg': `#e6e6e6`,
'@footer-color': `#7C7B98`,
'@row-bg': `#FFFFFF`,
'@row-color': `#212c32`,
'@row-alternative-bg': `#f5f5f5`,
'@row-alternative-color': `#253138`,
'@row-hover-bg': `#E8E8E8`,
'@row-hover-color': `fade(#394c57, 90%)`,
'@header-bg': `#FFFFFF`,
'@header-color': `#707070`,

      }
  }

getThemeVariableValue(variable: string): string {
    return this.themeOptions.variables[variable];
  }

    

getButtonThemeOptions (): ThemeOptions.ButtonThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-button',
        Roles: {
'global': '   button-status-global',
          'primary': '  button-status-primary',
          'info': '  button-status-info',
          'success': '  button-status-success',
          'warning': '  button-status-warning',
          'danger': '  button-status-danger',
          'default': '  button-status-default',
'xs': '  button-size-xs',
          'sm': '  button-size-sm',
          'med': '  button-size-med',
          'lg': '  button-size-lg',
          'xl': '  button-size-xl',
        },
},
      },
      
    };
    return options;
  }
getTextboxThemeOptions (): ThemeOptions.TextboxThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-textbox',
        Roles: {
'global': '   textbox-status-global',
          'primary': '  textbox-status-primary',
          'info': '  textbox-status-info',
          'success': '  textbox-status-success',
          'warning': '  textbox-status-warning',
          'danger': '  textbox-status-danger',
          'default': '  textbox-status-default',
'xs': '  textbox-size-xs',
          'sm': '  textbox-size-sm',
          'med': '  textbox-size-med',
          'lg': '  textbox-size-lg',
          'xl': '  textbox-size-xl',
        },
},
      },
      
    };
    return options;
  }
getDropDownBoxThemeOptions (): ThemeOptions.DropDownBoxThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-dropdownbox',
        Roles: {
'global': '   dropdownbox-status-global',
          'primary': '  dropdownbox-status-primary',
          'info': '  dropdownbox-status-info',
          'success': '  dropdownbox-status-success',
          'warning': '  dropdownbox-status-warning',
          'danger': '  dropdownbox-status-danger',
          'default': '  dropdownbox-status-default',
'xs': '  dropdownbox-size-xs',
          'sm': '  dropdownbox-size-sm',
          'med': '  dropdownbox-size-med',
          'lg': '  dropdownbox-size-lg',
          'xl': '  dropdownbox-size-xl',
        },
},
      },
      
    };
    return options;
  }
getRadioButtonThemeOptions (): ThemeOptions.RadioButtonThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-optionbutton',
        Roles: {
'global': '   optionbutton-status-global',
          'primary': ' p-primary p-primary-o state optionbutton-status-primary',
          'info': ' p-info p-info-o state optionbutton-status-info',
          'success': ' p-success p-success-o state optionbutton-status-success',
          'warning': ' p-warning p-warning-o state optionbutton-status-warning',
          'danger': ' p-danger p-danger-o state optionbutton-status-danger',
          'default': ' p-default p-default-o state optionbutton-status-default',
'xs': '  optionbutton-size-xs',
          'sm': '  optionbutton-size-sm',
          'med': '  optionbutton-size-med',
          'lg': '  optionbutton-size-lg',
          'xl': '  optionbutton-size-xl',
        },
},
      },
      
    };
    return options;
  }
getTextAreaThemeOptions (): ThemeOptions.TextAreaThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-textarea',
        Roles: {
'global': '   textarea-status-global',
          'primary': '  textarea-status-primary',
          'info': '  textarea-status-info',
          'success': '  textarea-status-success',
          'warning': '  textarea-status-warning',
          'danger': '  textarea-status-danger',
          'default': '  textarea-status-default',
'xs': '  textarea-size-xs',
          'sm': '  textarea-size-sm',
          'med': '  textarea-size-med',
          'lg': '  textarea-size-lg',
          'xl': '  textarea-size-xl',
        },
},
      },
    
    };
    return options;
  }
getRichTextBoxThemeOptions (): ThemeOptions.RichTextBoxThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-richtextbox',
        Roles: {
'global': '   richtextbox-status-global',
          'primary': '  richtextbox-status-primary',
          'info': '  richtextbox-status-info',
          'success': '  richtextbox-status-success',
          'warning': '  richtextbox-status-warning',
          'danger': '  richtextbox-status-danger',
          'default': '  richtextbox-status-default',
'xs': '  richtextbox-size-xs',
          'sm': '  richtextbox-size-sm',
          'med': '  richtextbox-size-med',
          'lg': '  richtextbox-size-lg',
          'xl': '  richtextbox-size-xl',
        },
},
      },
      
    };
    return options;
  }
getLinkThemeOptions (): ThemeOptions.LinkThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-hyperlink',
        Roles: {
'global': '   hyperlink-status-global',
          'primary': '  hyperlink-status-primary',
          'info': '  hyperlink-status-info',
          'success': '  hyperlink-status-success',
          'warning': '  hyperlink-status-warning',
          'danger': '  hyperlink-status-danger',
          'default': '  hyperlink-status-default',
'xs': '  hyperlink-size-xs',
          'sm': '  hyperlink-size-sm',
          'med': '  hyperlink-size-med',
          'lg': '  hyperlink-size-lg',
          'xl': '  hyperlink-size-xl',
        },
},
      },
      
    };
    return options;
  }
getCheckBoxThemeOptions (): ThemeOptions.CheckBoxOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-checkbox',
        Roles: {
'global': '   checkbox-status-global',
          'primary': '  checkbox-status-primary',
          'info': '  checkbox-status-info',
          'success': '  checkbox-status-success',
          'warning': '  checkbox-status-warning',
          'danger': '  checkbox-status-danger',
          'default': '  checkbox-status-default',

        },
},
      },
      
    };
    return options;
  }
getImageBoxThemeOptions (): ThemeOptions.ImageBoxThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-imagebox',
        Roles: { },
},
      },
      
    };
    return options;
  }
getChartThemeOptions (): ThemeOptions.ChartThemeOptions {
    const options = {
        'Standard': {
        'Pallette': ["#DEEEFF","#0F88FF","#007CF7","#0071E0","#C7E3FF","#B0D8FF","#99CCFF","#82C1FF","#6BB5FF","#54AAFF","#3D9EFF","#2693FF"],
        'PieLabelColor': '#FFFFFF'
    },


    };
    return options;
  }
getProgressBarThemeOptions (): ThemeOptions.ProgressBarThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-progressbar',
        Roles: {
'global': '   progressbar-status-global',
          'primary': '  progressbar-status-primary',
          'info': '  progressbar-status-info',
          'success': '  progressbar-status-success',
          'warning': '  progressbar-status-warning',
          'danger': '  progressbar-status-danger',
          'default': '  progressbar-status-default',
'xs': '  progressbar-size-xs',
          'sm': '  progressbar-size-sm',
          'med': '  progressbar-size-med',
          'lg': '  progressbar-size-lg',
          'xl': '  progressbar-size-xl',
        },
},
      },
      
    };
    return options;
  }
getLabelThemeOptions (): ThemeOptions.LabelThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-label',
        Roles: {
'global': '   label-status-global',
          'primary': '  label-status-primary',
          'info': '  label-status-info',
          'success': '  label-status-success',
          'warning': '  label-status-warning',
          'danger': '  label-status-danger',
          'default': '  label-status-default',
'xs': '  label-size-xs',
          'sm': '  label-size-sm',
          'med': '  label-size-med',
          'lg': '  label-size-lg',
          'xl': '  label-size-xl',
        },
},
      },
      
    };
    return options;
  }
getListThemeOptions (): ThemeOptions.ListThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-list',
        Roles: {
'global': '   list-status-global',
          'primary': '  list-status-primary',
          'info': '  list-status-info',
          'success': '  list-status-success',
          'warning': '  list-status-warning',
          'danger': '  list-status-danger',
          'default': '  list-status-default',

        },
},
        Icons: {
          refresh: "pi pi-refresh",
          reset: "pi pi-replay",
          group: "pi pi-table",
          export: "pi pi-download",
          view: "pi pi-view",
          hideShow: "pi pi-table",
          remove: "pi pi-times",
          filter: "pi pi-filter",
          search: "pi pi-search",
          aggregator: "pi pi-dashboard",
          menuItem: "pi pi-circle",
        },
      },

    };
    return options;
  }
getRepeaterControlThemeOptions (): ThemeOptions.RepeaterControlThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-repeatercontrol',
        Roles: { },
},
      },
      
    };
    return options;
  }
getContainerControlThemeOptions (): ThemeOptions.ContainerControlThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-containercontrol',
        Roles: { },
},
      },
            'TopBar': {
        Classes: {
        Global: ' zapp-containercontrol-TopBar',
        Roles: { },
},
},
    };
    return options;
  }
getTableThemeOptions (): ThemeOptions.TableThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-table',
        Roles: {
'global': '   table-status-global',
          'primary': '  table-status-primary',
          'info': '  table-status-info',
          'success': '  table-status-success',
          'warning': '  table-status-warning',
          'danger': '  table-status-danger',
          'default': '  table-status-default',

        },
},
      },
      
    };
    return options;
  }
getPanelThemeOptions(): ThemeOptions.PanelThemeOptions {
    const options = {
      'Standard': {
        Panel: { 
    Classes: {
        Global: ' zapp-panel',
        Roles: {
'global': '   panel-status-global',
          'primary': '  panel-status-primary',
          'info': '  panel-status-info',
          'success': '  panel-status-success',
          'warning': '  panel-status-warning',
          'danger': '  panel-status-danger',
          'default': '  panel-status-default',

        },
},
},
        Header: {
    Classes: {
        Global: ' zapp-panel-header',
        Roles: {
'global': '   panel-header-status-global',
          'primary': '  panel-header-status-primary',
          'info': '  panel-header-status-info',
          'success': '  panel-header-status-success',
          'warning': '  panel-header-status-warning',
          'danger': '  panel-header-status-danger',
          'default': '  panel-header-status-default',

        },
},
},
        Body: {
    Classes: {
        Global: ' zapp-panel-body',
        Roles: {
'global': '   panel-body-status-global',
          'primary': '  panel-body-status-primary',
          'info': '  panel-body-status-info',
          'success': '  panel-body-status-success',
          'warning': '  panel-body-status-warning',
          'danger': '  panel-body-status-danger',
          'default': '  panel-body-status-default',

        },
},
},
        Footer: {
    Classes: {
        Global: ' zapp-panel-footer',
        Roles: {
'global': '   panel-footer-status-global',
          'primary': '  panel-footer-status-primary',
          'info': '  panel-footer-status-info',
          'success': '  panel-footer-status-success',
          'warning': '  panel-footer-status-warning',
          'danger': '  panel-footer-status-danger',
          'default': '  panel-footer-status-default',

        },
},
},
      },
      
    };
    return options;
  }
getModalThemeOptions(): ThemeOptions.ModalThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-modal',
        Roles: {
'global': '   modal-status-global',
          'primary': '  modal-status-primary',
          'info': '  modal-status-info',
          'success': '  modal-status-success',
          'warning': '  modal-status-warning',
          'danger': '  modal-status-danger',
          'default': '  modal-status-default',

        },
},
      },
      
    };
    return options;
}

getLayoutThemeOptions(): ThemeOptions.LayoutThemeOptions {
  const options = {
    LayoutClass: 'layout Layout-status-global',
    RowClass: ' Row-status-global',
    ColumnClass: (size: number, sizeMd: number, sizeLg: number, sizeXl: number) => {
        
        const colMd = sizeMd != null && sizeMd > 0 ? `md:col-${sizeMd}` : '';
        const colLg = sizeLg != null && sizeLg > 0 ? `lg:col-${sizeLg}` : '';
        const colXl = sizeXl != null && sizeXl > 0 ? `xl:col-${sizeXl}` : '';

        return `col-${size} ${colMd} ${colLg} ${colXl} Column-status-global`;
    },
  };
  return options;
}

getTabContainerThemeOptions(orientation: string): ThemeOptions.TabContainerThemeOptions {
const options: ThemeOptions.TabContainerThemeOptions = {
'Standard': {
        Classes: {
        Global: ' zapp-tabcontainer',
        Roles: {
'global': '   tabcontainer-status-global',
          'primary': '  tabcontainer-status-primary',
          'info': '  tabcontainer-status-info',
          'success': '  tabcontainer-status-success',
          'warning': '  tabcontainer-status-warning',
          'danger': '  tabcontainer-status-danger',
          'default': '  tabcontainer-status-default',

        },
},
      },              
                
            };

            return options;
        }
getTabHeaderThemeOptions(orientation: string): ThemeOptions.TabHeaderThemeOptions {
const options: ThemeOptions.TabHeaderThemeOptions = {
'Standard': {
        HeaderNavClass: '',
        HeaderNavItemClass: 'nav-item',
        HeaderNavItemLinkClass: 'nav-link',
        TabsHostClass: 'paneTabs',
        TabContentClass: '',
        SideNavClass: (orientation === 'left' || orientation === 'right') ? 'col-md-3 tabheader-status-default' : 'tabheader-status-default',
        TabsContentClass: (orientation === 'left' || orientation === 'right') ? 'row' : '',
        TabsSideContentClass: (orientation === 'left' || orientation === 'right') ? 'col-md-9' : '',
        Classes: {
        Global: ' zapp-tabheader',
        Roles: {
'global': '   tabheader-status-global',
          'primary': '  tabheader-status-primary',
          'info': '  tabheader-status-info',
          'success': '  tabheader-status-success',
          'warning': '  tabheader-status-warning',
          'danger': '  tabheader-status-danger',
          'default': '  tabheader-status-default',

        },
},
      },              
                
            };

            return options;
        }
getTabContentThemeOptions(orientation: string): ThemeOptions.TabContentThemeOptions {
const options: ThemeOptions.TabContentThemeOptions = {
'Standard': {
        Classes: {
        Global: ' zapp-tabcontent',
        Roles: {
'global': '   tabcontent-status-global',
          'primary': '  tabcontent-status-primary',
          'info': '  tabcontent-status-info',
          'success': '  tabcontent-status-success',
          'warning': '  tabcontent-status-warning',
          'danger': '  tabcontent-status-danger',
          'default': '  tabcontent-status-default',

        },
},
      },              
                
            };

            return options;
        }
getTabPageThemeOptions(orientation: string): ThemeOptions.TabPageThemeOptions {
const options: ThemeOptions.TabPageThemeOptions = {
'Standard': {
        Classes: {
        Global: ' zapp-tabpage',
        Roles: {
'global': '   tabpage-status-global',
          'primary': '  tabpage-status-primary',
          'info': '  tabpage-status-info',
          'success': '  tabpage-status-success',
          'warning': '  tabpage-status-warning',
          'danger': '  tabpage-status-danger',
          'default': '  tabpage-status-default',

        },
},
      },              
                
            };

            return options;
        }

getIconismThemeOptions (): ThemeOptions.IconismThemeOptions {
    const options = {
      Variations: { 
        'Standard': {
          Classes: {
        Global: ' zapp-icon',
        Roles: {
'global': '   icon-status-global',
          'primary': '  icon-status-primary',
          'info': '  icon-status-info',
          'success': '  icon-status-success',
          'warning': '  icon-status-warning',
          'danger': '  icon-status-danger',
          'default': '  icon-status-default',
'xs': '  icon-size-xs',
          'sm': '  icon-size-sm',
          'med': '  icon-size-med',
          'lg': '  icon-size-lg',
          'xl': '  icon-size-xl',
        },
},
         },
        
      },
      Icons: {
        "remove": "pi pi-times",
"align-center": "pi pi-align-center",
"align-justify": "pi pi-align-justify",
"align-left": "pi pi-align-left",
"align-right": "pi pi-align-right",
"amazon": "pi pi-amazon",
"android": "pi pi-android",
"angle-double-down": "pi pi-angle-double-down",
"angle-double-left": "pi pi-angle-double-left",
"angle-double-right": "pi pi-angle-double-right",
"angle-double-up": "pi pi-angle-double-up",
"angle-down": "pi pi-angle-down",
"angle-left": "pi pi-angle-left",
"angle-right": "pi pi-angle-right",
"angle-up": "pi pi-angle-up",
"apple": "pi pi-apple",
"arrow-circle-down": "pi pi-arrow-circle-down",
"arrow-circle-left": "pi pi-arrow-circle-left",
"arrow-circle-right": "pi pi-arrow-circle-right",
"arrow-circle-up": "pi pi-arrow-circle-up",
"arrow-down": "pi pi-arrow-down",
"arrow-down-left": "pi pi-arrow-down-left",
"arrow-down-right": "pi pi-arrow-down-right",
"arrow-left": "pi pi-arrow-left",
"arrow-right": "pi pi-arrow-right",
"arrow-up": "pi pi-arrow-up",
"arrow-up-left": "pi pi-arrow-up-left",
"arrow-up-right": "pi pi-arrow-up-right",
"arrows-h": "pi pi-arrows-h",
"arrows-v": "pi pi-arrows-v",
"at": "pi pi-at",
"backward": "pi pi-backward",
"ban": "pi pi-ban",
"bars": "pi pi-bars",
"bell": "pi pi-bell",
"bolt": "pi pi-bolt",
"book": "pi pi-book",
"bookmark": "pi pi-bookmark",
"bookmark-fill": "pi pi-bookmark-fill",
"box": "pi pi-box",
"briefcase": "pi pi-briefcase",
"building": "pi pi-building",
"calendar": "pi pi-calendar",
"calendar-minus": "pi pi-calendar-minus",
"calendar-plus": "pi pi-calendar-plus",
"calendar-times": "pi pi-calendar-times",
"camera": "pi pi-camera",
"car": "pi pi-car",
"caret-down": "pi pi-caret-down",
"caret-left": "pi pi-caret-left",
"caret-right": "pi pi-caret-right",
"caret-up": "pi pi-caret-up",
"chart-bar": "pi pi-chart-bar",
"chart-line": "pi pi-chart-line",
"chart-pie": "pi pi-chart-pie",
"check": "pi pi-check",
"check-circle": "pi pi-check-circle",
"check-square": "pi pi-check-square",
"chevron-circle-down": "pi pi-chevron-circle-down",
"chevron-circle-left": "pi pi-chevron-circle-left",
"chevron-circle-right": "pi pi-chevron-circle-right",
"chevron-circle-up": "pi pi-chevron-circle-up",
"chevron-down": "pi pi-chevron-down",
"chevron-left": "pi pi-chevron-left",
"chevron-right": "pi pi-chevron-right",
"chevron-up": "pi pi-chevron-up",
"circle": "pi pi-circle",
"circle-fill": "pi pi-circle-fill",
"clock": "pi pi-clock",
"clone": "pi pi-clone",
"cloud": "pi pi-cloud",
"cloud-download": "pi pi-cloud-download",
"cloud-upload": "pi pi-cloud-upload",
"code": "pi pi-code",
"cog": "pi pi-cog",
"comment": "pi pi-comment",
"comments": "pi pi-comments",
"compass": "pi pi-compass",
"copy": "pi pi-copy",
"credit-card": "pi pi-credit-card",
"database": "pi pi-database",
"desktop": "pi pi-desktop",
"directions": "pi pi-directions",
"directions-alt": "pi pi-directions-alt",
"discord": "pi pi-discord",
"dollar": "pi pi-dollar",
"download": "pi pi-download",
"eject": "pi pi-eject",
"ellipsis-h": "pi pi-ellipsis-h",
"ellipsis-v": "pi pi-ellipsis-v",
"envelope": "pi pi-envelope",
"euro": "pi pi-euro",
"exclamation-circle": "pi pi-exclamation-circle",
"exclamation-triangle": "pi pi-exclamation-triangle",
"external-link": "pi pi-external-link",
"eye": "pi pi-eye",
"eye-slash": "pi pi-eye-slash",
"facebook": "pi pi-facebook",
"fast-backward": "pi pi-fast-backward",
"fast-forward": "pi pi-fast-forward",
"file": "pi pi-file",
"file-excel": "pi pi-file-excel",
"file-pdf": "pi pi-file-pdf",
"filter": "pi pi-filter",
"filter-fill": "pi pi-filter-fill",
"filter-slash": "pi pi-filter-slash",
"flag": "pi pi-flag",
"flag-fill": "pi pi-flag-fill",
"folder": "pi pi-folder",
"folder-open": "pi pi-folder-open",
"forward": "pi pi-forward",
"github": "pi pi-github",
"globe": "pi pi-globe",
"google": "pi pi-google",
"hashtag": "pi pi-hashtag",
"heart": "pi pi-heart",
"heart-fill": "pi pi-heart-fill",
"history": "pi pi-history",
"home": "pi pi-home",
"id-card": "pi pi-id-card",
"image": "pi pi-image",
"images": "pi pi-images",
"inbox": "pi pi-inbox",
"info": "pi pi-info",
"info-circle": "pi pi-info-circle",
"instagram": "pi pi-instagram",
"key": "pi pi-key",
"link": "pi pi-link",
"linkedin": "pi pi-linkedin",
"list": "pi pi-list",
"lock": "pi pi-lock",
"lock-open": "pi pi-lock-open",
"map": "pi pi-map",
"map-marker": "pi pi-map-marker",
"microsoft": "pi pi-microsoft",
"minus": "pi pi-minus",
"minus-circle": "pi pi-minus-circle",
"mobile": "pi pi-mobile",
"money-bill": "pi pi-money-bill",
"moon": "pi pi-moon",
"palette": "pi pi-palette",
"paperclip": "pi pi-paperclip",
"pause": "pi pi-pause",
"paypal": "pi pi-paypal",
"pencil": "pi pi-pencil",
"percentage": "pi pi-percentage",
"phone": "pi pi-phone",
"play": "pi pi-play",
"plus": "pi pi-plus",
"plus-circle": "pi pi-plus-circle",
"pound": "pi pi-pound",
"power-off": "pi pi-power-off",
"prime": "pi pi-prime",
"print": "pi pi-print",
"qrcode": "pi pi-qrcode",
"question": "pi pi-question",
"question-circle": "pi pi-question-circle",
"reddit": "pi pi-reddit",
"refresh": "pi pi-refresh",
"replay": "pi pi-replay",
"reply": "pi pi-reply",
"save": "pi pi-save",
"search": "pi pi-search",
"search-minus": "pi pi-search-minus",
"search-plus": "pi pi-search-plus",
"send": "pi pi-send",
"server": "pi pi-server",
"share-alt": "pi pi-share-alt",
"shield": "pi pi-shield",
"shopping-bag": "pi pi-shopping-bag",
"shopping-cart": "pi pi-shopping-cart",
"sign-in": "pi pi-sign-in",
"sign-out": "pi pi-sign-out",
"sitemap": "pi pi-sitemap",
"slack": "pi pi-slack",
"sliders-h": "pi pi-sliders-h",
"sliders-v": "pi pi-sliders-v",
"sort": "pi pi-sort",
"sort-alpha-down": "pi pi-sort-alpha-down",
"sort-alpha-down-alt": "pi pi-sort-alpha-down-alt",
"sort-alpha-up": "pi pi-sort-alpha-up",
"sort-alpha-up-alt": "pi pi-sort-alpha-up-alt",
"sort-alt": "pi pi-sort-alt",
"sort-alt-slash": "pi pi-sort-alt-slash",
"sort-amount-down": "pi pi-sort-amount-down",
"sort-amount-down-alt": "pi pi-sort-amount-down-alt",
"sort-amount-up": "pi pi-sort-amount-up",
"sort-amount-up-alt": "pi pi-sort-amount-up-alt",
"sort-down": "pi pi-sort-down",
"sort-numeric-down": "pi pi-sort-numeric-down",
"sort-numeric-down-alt": "pi pi-sort-numeric-down-alt",
"sort-numeric-up": "pi pi-sort-numeric-up",
"sort-numeric-up-alt": "pi pi-sort-numeric-up-alt",
"sort-up": "pi pi-sort-up",
"spinner": "pi pi-spinner",
"star": "pi pi-star",
"star-fill": "pi pi-star-fill",
"step-backward": "pi pi-step-backward",
"step-backward-alt": "pi pi-step-backward-alt",
"step-forward": "pi pi-step-forward",
"step-forward-alt": "pi pi-step-forward-alt",
"stop": "pi pi-stop",
"stop-circle": "pi pi-stop-circle",
"sun": "pi pi-sun",
"sync": "pi pi-sync",
"table": "pi pi-table",
"tablet": "pi pi-tablet",
"tag": "pi pi-tag",
"tags": "pi pi-tags",
"telegram": "pi pi-telegram",
"th-large": "pi pi-th-large",
"thumbs-down": "pi pi-thumbs-down",
"thumbs-up": "pi pi-thumbs-up",
"ticket": "pi pi-ticket",
"times": "pi pi-times",
"times-circle": "pi pi-times-circle",
"trash": "pi pi-trash",
"twitter": "pi pi-twitter",
"undo": "pi pi-undo",
"unlock": "pi pi-unlock",
"upload": "pi pi-upload",
"user": "pi pi-user",
"user-edit": "pi pi-user-edit",
"user-minus": "pi pi-user-minus",
"user-plus": "pi pi-user-plus",
"users": "pi pi-users",
"video": "pi pi-video",
"vimeo": "pi pi-vimeo",
"volume-down": "pi pi-volume-down",
"volume-off": "pi pi-volume-off",
"volume-up": "pi pi-volume-up",
"wallet": "pi pi-wallet",
"whatsapp": "pi pi-whatsapp",
"wifi": "pi pi-wifi",
"window-maximize": "pi pi-window-maximize",
"window-minimize": "pi pi-window-minimize",
"youtube": "pi pi-youtube"
      }
    };
    return options;
  }
getDateTimeBoxThemeOptions (): ThemeOptions.DateTimeBoxThemeOptions {
    const options = {
      'Standard': {
            Input: {
              Classes: {
        Global: ' zapp-datetimebox',
        Roles: {
'global': '   datetimebox-status-global',
          'primary': '  datetimebox-status-primary',
          'info': '  datetimebox-status-info',
          'success': '  datetimebox-status-success',
          'warning': '  datetimebox-status-warning',
          'danger': '  datetimebox-status-danger',
          'default': '  datetimebox-status-default',
'xs': '  datetimebox-size-xs',
          'sm': '  datetimebox-size-sm',
          'med': '  datetimebox-size-med',
          'lg': '  datetimebox-size-lg',
          'xl': '  datetimebox-size-xl',
        },
},
          },
            Container: {
              Classes: {
        Global: ' zapp-datetimebox',
        Roles: {
'global': '   datetimebox-status-global',
          'primary': '  datetimebox-status-primary',
          'info': '  datetimebox-status-info',
          'success': '  datetimebox-status-success',
          'warning': '  datetimebox-status-warning',
          'danger': '  datetimebox-status-danger',
          'default': '  datetimebox-status-default',

        },
},
          }
      },
    
    };
    return options;
  }
getPasswordTextboxThemeOptions (): ThemeOptions.PasswordTextboxThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-passwordtextbox',
        Roles: {
'global': '   passwordtextbox-status-global',
          'primary': '  passwordtextbox-status-primary',
          'info': '  passwordtextbox-status-info',
          'success': '  passwordtextbox-status-success',
          'warning': '  passwordtextbox-status-warning',
          'danger': '  passwordtextbox-status-danger',
          'default': '  passwordtextbox-status-default',
'xs': '  passwordtextbox-size-xs',
          'sm': '  passwordtextbox-size-sm',
          'med': '  passwordtextbox-size-med',
          'lg': '  passwordtextbox-size-lg',
          'xl': '  passwordtextbox-size-xl',
        },
},
      },
      
    };
    return options;
  }
getFileAttachmentThemeOptions (): ThemeOptions.FileAttachmentThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-fileattachment',
        Roles: { },
},
      },
      
    };
    return options;
  }
getCalendarThemeOptions (): ThemeOptions.CalendarThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-calendar',
        Roles: {
'global': '   calendar-status-global',
          'primary': '  calendar-status-primary',
          'info': '  calendar-status-info',
          'success': '  calendar-status-success',
          'warning': '  calendar-status-warning',
          'danger': '  calendar-status-danger',
          'default': '  calendar-status-default',
'xs': '',
          'sm': '',
          'med': '',
          'lg': '',
          'xl': '',
        },
},
      },
      
    };
    return options;
  }
getMenuThemeOptions(): ThemeOptions.MenuThemeOptions {
  return { 
  'Standard': {
     Classes: {
        Global: ' zapp-menucontrol',
        Roles: {
'global': '   menucontrol-status-global',
          'primary': '  menucontrol-status-primary',
          'info': '  menucontrol-status-info',
          'success': '  menucontrol-status-success',
          'warning': '  menucontrol-status-warning',
          'danger': '  menucontrol-status-danger',
          'default': '  menucontrol-status-default',

        },
},
    },
    
  };
};
getDataSourceGridThemeOptions (): ThemeOptions.DataSourceGridThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-datasourcegrid',
        Roles: {
'global': '   datasourcegrid-status-global',
          'primary': '  datasourcegrid-status-primary',
          'info': '  datasourcegrid-status-info',
          'success': '  datasourcegrid-status-success',
          'warning': '  datasourcegrid-status-warning',
          'danger': '  datasourcegrid-status-danger',
          'default': '  datasourcegrid-status-default',

        },
},
      },
      
    };
    return options;
  }
getGridThemeOptions (): ThemeOptions.GridThemeOptions {
    const options = {
      'Standard': {
        Table: { 
    Classes: {
        Global: ' zapp-grid',
        Roles: {
'global': '   grid-status-global',
          'primary': '  grid-status-primary',
          'info': '  grid-status-info',
          'success': '  grid-status-success',
          'warning': '  grid-status-warning',
          'danger': '  grid-status-danger',
          'default': '  grid-status-default',

        },
},
},
        AddItemLabel: {
    Classes: {
        Global: ' zapp-additemlabel',
        Roles: {
'global': '   additemlabel-status-global',
          'primary': '  additemlabel-status-primary',
          'info': '  additemlabel-status-info',
          'success': '  additemlabel-status-success',
          'warning': '  additemlabel-status-warning',
          'danger': '  additemlabel-status-danger',
          'default': '  additemlabel-status-default',

        },
},
},
        RemoveItemLabel: {
    Classes: {
        Global: ' zapp-removeitemlabel',
        Roles: { },
},
},
        Pager: {
    Classes: {
        Global: ' zapp-pager',
        Roles: {
'global': '   pager-status-global',
          'primary': '  pager-status-primary',
          'info': '  pager-status-info',
          'success': '  pager-status-success',
          'warning': '  pager-status-warning',
          'danger': '  pager-status-danger',
          'default': '  pager-status-default',

        },
},
},
      },
      
    };
    return options;
  }
getFormGroupThemeOptions (): ThemeOptions.FormGroupThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-formgroup',
        Roles: { },
},
      },
      
    };
    return options;
  }
getBreadcrumbThemeOptions (): ThemeOptions.BreadcrumbThemeOptions {
    const options = {
      'Standard': {
        Classes: {
        Global: ' zapp-breadcrumbcontrol',
        Roles: {
'global': '   breadcrumbcontrol-status-global',
          'primary': '  breadcrumbcontrol-status-primary',
          'info': '  breadcrumbcontrol-status-info',
          'success': '  breadcrumbcontrol-status-success',
          'warning': '  breadcrumbcontrol-status-warning',
          'danger': '  breadcrumbcontrol-status-danger',
          'default': '  breadcrumbcontrol-status-default',
'xs': '',
          'sm': '',
          'med': '',
          'lg': '',
          'xl': '',
        },
},
      },
      
    };
    return options;
  }

}
