import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { environment } from 'src/environments/environment';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    if (environment.windowsAuthentication) {
      req = req.clone({ withCredentials: true });
      return next.handle(req);
    }
    
    const idToken = this.authService.getToken();

    if (idToken !== undefined) {
      const cloned = req.clone({
        headers: req.headers
          .set("Authorization", `Bearer ${idToken}`)
          .set("X-Requested-With", "XMLHttpRequest")
      });

      return next.handle(cloned);
    }
    else {
      const cloned = req.clone({
        headers: req.headers
          .set("X-Requested-With", "XMLHttpRequest")
      });

      return next.handle(cloned);
    }
  }
}
