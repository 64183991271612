import { Input, OnInit, HostBinding, AfterViewInit, Component, TemplateRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { zAppDevComponentStatus } from '../component-status';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';

import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';
import { zAppDevBaseComponent } from '../basecomponent/base.component';
import { Table } from 'primeng/table';

// import * as componentsActions from '../components.actions';

@Component({
  selector: 'zappDataTable',
  templateUrl: './grid.component.html',
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevGridComponent }]
})

export class zAppDevGridComponent extends zAppDevBaseComponent implements AfterViewInit {

  _model: Array<any>;
  @Input()
  get model(): Array<any> {
    return this._model;
  }
  set model(model: Array<any>) {
    this._model = model ?? [];
    this.updateTotalRecords();
    this.updateLast();
  }

  _controls: Array<any>;
  @Input()
  get controls(): Array<any> {
    return this._controls;
  }
  set controls(controls: Array<any>) {
    this._controls = controls ?? [];
    console.log(this._controls);
  }

  // for conditional formattings
  @Input() disabled: boolean = false;

  @Input() addConditionals: any = {};

  @Input() showTotalRecordsNumber = false;

  @Input() showNoRecordsMessage = false;

  @Input() noRecordsResource = '';

  @Input() hoverEffect = false;

  @Input() alternateRowColor = false;

  @Input() status: zAppDevComponentStatus = 'global';

  @Input() options: any;

  @Input() class: string = '';

  @Input() variation: string = 'Standard';

  @Input() name: string = '';

  @Input() paginator: boolean = false;

  @Input() paginatorPosition: string = "bottom";

  @Input() showRowsPerPage: number = 5;

  @Input() allowAddRows: boolean = false;

  @Input() addItem: () => any;

  @Input() allowremoveRows: boolean = false;

  @Input() showDeleteWarningMessage: boolean = false;

  @Input() deleteWarningResource: string = '';

  @Input() globalFilterFields: string[] = [];

  @Input('footer') footerTpl: TemplateRef<any>;
  @Input('header') headerTpl: TemplateRef<any>;
  @Input('body') bodyTpl: TemplateRef<any>;
  @Input('paginatorleft') paginatorleftTpl: TemplateRef<any>;

  @Output()
  public valueChange = new EventEmitter<any>();

  @Output()
  public onAddRow = new EventEmitter<any>();

  @Output()
  public onDeleteRow = new EventEmitter<any>();

  themeOptions: ThemeOptions.GridThemeOptions;

  first: number = 0;

  // fix 'Showing 0 of ...'
  get firstNumberZeroBasedFix(): 0 | 1 { 
    return this.totalRecords <= 0 ? 0 : 1
  }

  last: number = 0;
  totalRecords: number = 0;
  
  constructor(private themeService: IThemeSevice, private confirmationService: ConfirmationService, private translate: TranslateService, protected elementRef: ElementRef) {
    super(elementRef);
    this.themeOptions = this.themeService.getGridThemeOptions();
  }

  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.hostClasses = [
      this.rootClass(),
      this.class,
      this.statusClass(),
      this.extraClass(),
    ].join(' ');
    this.updateTotalRecords();
    this.updateLast();
  }

  rootClass() {
    let rootClasses = (this.themeOptions ?? [])[this.variation]?.Table?.Classes?.Global ?? '';
    rootClasses += ` ${(this.themeOptions ?? [])[this.variation]?.AddItemLabel?.Classes?.Global ?? ''}`;
    rootClasses += ` ${(this.themeOptions ?? [])[this.variation]?.Body?.RemoveItemLabel?.Global ?? ''}`;
    rootClasses += ` ${(this.themeOptions ?? [])[this.variation]?.Pager?.Classes?.Global ?? ''}`;

    return rootClasses + ' zapp-cell';
  }

  statusClass() {
    if (this.variation in this.themeOptions) {
      return this.themeOptions[this.variation]?.Table?.Classes?.Roles[this.status];
    }
    return '';
  }

  extraClass() {
    return (this.showTotalRecordsNumber) ? "zappdev-show-total-records-number" : "zappdev-no-total-records-number";
  }

  trackByFunction(index: number, item: any) {
    return item._clientKey;
  }

  add() {
    if (this.disabled == true) {
      return;
    }
    const newItem = this.addItem ? this.addItem() : {};
    this.model = [...(this.model ?? []), newItem];
    this.valueChange.emit(this.model);
    this.goToLast();
    this.onAddRow.emit(this.model);

    this.updateTotalRecords();
    this.updateLast();
  }

  delete($event: any, item: any, index: number) {
    if (this.disabled == true) {
      return;
    }

    let message = this.translate.instant(this.deleteWarningResource);
    if (message == null || message.trim().length <= 0) {
      message = this.translate.instant('RES_WEBFORM_GRID_DeleteRow');
    }

    if (this.showDeleteWarningMessage) {
      this.confirmationService.confirm({
        key: 'confirmPopUp',
        target: $event?.target,
        message,
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'btn btn-default button-size-m button-status-success',
        rejectButtonStyleClass: 'btn btn-default button-size-m button-status-default',
        accept: () => {
          this.model.splice(index, 1);
          this.model = [...this.model];
          this.valueChange.emit(this.model);
          this.onDeleteRow.emit(this.model);
        },
      });
    } else {
      this.model.splice(index, 1);
      this.model = [...this.model];
      this.valueChange.emit(this.model);
      this.onDeleteRow.emit(this.model);
    }

    this.updateTotalRecords();
    this.updateLast();
  }

  clear(table: Table) {
    table.clear();
  }

  forceRefreshing = false;
  refresh() {
    this.updateTotalRecords();
    this.updateLast();

    this.forceRefreshing = true;
    setTimeout(() => {
      this.forceRefreshing = false;
    }, 10);
  }

  next() {
    const first = this.first + this.showRowsPerPage;
    if (first > this.totalRecords) {
      return;
    }
    this.first = first;
    this.updateLast();
  }

  prev() {
    const first = this.first - this.showRowsPerPage;
    if (first < 0) {
      return;
    }
    this.first = first;
    this.updateLast();
  }

  goToFirst() {
    this.first = 0;
    this.updateLast();
  }

  goToLast() {
    this.first = Math.floor(this.model.length / this.showRowsPerPage) * this.showRowsPerPage;
    this.updateLast();
  }

  goToPage(page: number) {
    const first = (page - 1) * this.showRowsPerPage;
    if (first < 0 || first > this.totalRecords) {
      return;
    }
    this.first = first;
    this.updateLast();
  }

  isLastPage(): boolean {
    return this.model ? this.first === (this.model.length - this.showRowsPerPage) : true;
  }

  updateLast() {
    let last = this.first + this.showRowsPerPage;
    if (last > this.totalRecords) {
      last = this.totalRecords;
    }

    this.last = last;
  }
  
  updateTotalRecords() {
    this.totalRecords = this.model?.length ?? 0;
  }

  onPage(event) {
    setTimeout(() => {
      this.updateLast();
    }, 10);
  }

  isFirstPage(): boolean {
    return this.model ? this.first === 0 : true;
  }
}
