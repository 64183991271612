import {Injectable} from "@angular/core";
import {lastValueFrom} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserProfileSettingsService {
  constructor(private httpClient: HttpClient) {
  }

  async getProfileSetting(key: string): Promise<string> {
    const request$ = this.httpClient.get(
      environment.appUrl + "_UserProfile/GetProfileSetting",
      {params: {key}, responseType: 'text'}
    )
      .pipe(take(1));
    return await lastValueFrom<string>(request$);
  }

  async setProfileSetting(key: string, value: string): Promise<string> {
    const request$ = this.httpClient.post(
      environment.appUrl + "_UserProfile/SetProfileSetting",
      {key, value},
      {responseType: 'text'}
    )
      .pipe(take(1));
    return await lastValueFrom<string>(request$);
  }
}
