import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ClientCommands, ClientCommandsService } from '@services/clientcommands.service';
import { Subject, takeUntil } from 'rxjs';
import { ContextService } from '../../../@core/services/context.service';
import { PubSubService } from '../../../@core/services/pubsub.service';
import { Joove } from '../../core/joove';
import { ZAppDevModalComponent } from '../modal/modal.component';
import { ZAppDevSidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'zapp-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.less']
})
export class zAppDevFormModalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected pubSubService: PubSubService,
    protected clientCommander: ClientCommandsService,
    protected contextService: ContextService,
    protected readonly titleService: Title,
  ) {

    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation?.extras?.state != null) {
      this.style = currentNavigation.extras.state.style ?? this.style;
      this.data = currentNavigation.extras.state.data;
    }

    this.contextService.context$.pipe(takeUntil(this.destroy$)).subscribe((context: Joove.IContext) => {
      this.updateTitle();
    });

  }

  protected destroy$: Subject<void> = new Subject<void>();

  @ViewChild('FormModal')
  formModal: ZAppDevModalComponent;

  @ViewChild('FormSidebar')
  formSidebar: ZAppDevSidebarComponent;

  style = 'width: 100vw';
  data: any = {};

  protected title: string = '';

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    if (this.data?.isSlide) {
      this.formSidebar.showDialog();
    } else {
      this.formModal.showDialog();
    }
  }

  closeModal() {

    if (this.data?.executeOnClose != null) {
      this.pubSubService.publish('form-modal-close', 'CLOSE_FORM', { data: this.data });
    }

    this.clientCommander.executeCommand(ClientCommands.CLOSE_FORM);
  }

  updateTitle() {
    const pageTitle = this.titleService.getTitle();
    this.title = pageTitle.substring(0, pageTitle.indexOf('|'));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

  }

}
