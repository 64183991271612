import { BehaviorSubject } from 'rxjs';

export abstract class ISignalRService {
  connectionEstablished$ = new BehaviorSubject<boolean>(false);

  public abstract buildConnection(url: string) : void;

  public abstract connect() : void;

  public abstract disconnect() : void;
}
