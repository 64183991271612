import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ZAPPDEV_DOCUMENT } from './theme.options';
import { ZAppDevSpinnerService } from './services/spinner.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ZAppDevThemeModule {
  static forRoot(): ModuleWithProviders<ZAppDevThemeModule> {

    return {
      ngModule: ZAppDevThemeModule,
      providers: [
        { provide: ZAPPDEV_DOCUMENT, useExisting: DOCUMENT },
        ZAppDevSpinnerService,
      ],
    };
  }
}
