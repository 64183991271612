export enum RuleActions{
    None,
    Enable,
    Hide,
    Disable,
    ChangeStyle,
    MakeReadOnly,
    Show,
    MakeEditable,
    Expand,
    Collapse,
    ApplyCssClass,
    RemoveCssClass,
    Required,
    NotRequired,
    SetColorRole
}