import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { IConditionalFormattingComponent } from "../../rule-engine/directives/condition-formatting.directive";
import { zAppDevComponentStatus } from "../component-status";
import { zAppDevComponentSize } from '@framework/components/component-size';
import { transformStyleToObject } from "../../common/utils";

@Component({
  template: ``
})
export abstract class zAppDevBaseComponent implements OnInit, OnDestroy, IConditionalFormattingComponent {

  constructor(protected elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  @Input() class: string = '';

  @Input() required: boolean = false;

  @Input() readonly: boolean = false;
  
  @Input() disabled?: boolean = false;

  @Input() status?: zAppDevComponentStatus;

  @Input() size?: zAppDevComponentSize;

  @Input() variation: string = 'Standard';

  _elementRef: ElementRef;

  protected options: any;

  hidden: boolean = false;
  collapsed?: boolean = false;
  noPermission?: boolean;

  statusSubject: BehaviorSubject<zAppDevComponentStatus> = new BehaviorSubject(null);

  hostClassesArray: string[] = [];
  styleClassesArray: string[] = [];

  @Input() style: string = '';

  get _style(): any {
    return transformStyleToObject(this.style);
  }

  @HostBinding('style')
  get hostStyle(): any {
    return this._style;
  }

  @HostBinding('class')
  get hostClasses(): string {
    return this.hostClassesArray.join(' ');
  }
  set hostClasses(classes: string) {
    this.hostClassesArray = classes.split(' ');
  }

  get styleClasses(): string {
    return this.styleClassesArray.join(' ');
  }
  set styleClasses(classes: string) {
    this.styleClassesArray = classes.split(' ');
  }

  @HostBinding('class.required')
  get requiredField() {
    return this.required === true;
  }

  isList: boolean = false;

  protected destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.hostClassesArray = this.cssHostClasses();
    this.styleClassesArray = this.cssStyleClasses();
  }

  statusClass() {
    return (this.options ?? [])[this.variation]?.Classes.Roles[this.status] ?? '';
  }

  sizeClass() {
    return (this.options ?? [])[this.variation]?.Classes.Roles[this.size] ?? '';
  }

  rootClass() {
    return (this.options ?? [])[this.variation]?.Classes?.Global ?? '';
  }

  setStatus(status: zAppDevComponentStatus) {
    this.status = status;
    this.statusSubject.next(this.status);
    this.hostClassesArray = this.cssHostClasses();
  }

  applyCssClasses(classes: string) {
    this.styleClassesArray = [...this.styleClassesArray, ...classes.split(' ')];
  }

  removeCssClasses(classes: string) {
    const classesArr = classes.split(' ');
    this.styleClassesArray = this.styleClassesArray.filter(c => !classesArr.some(cls => cls === c));
  }

  cssStyleClasses(): string[] {
    return [
      this.class
    ];
  }

  cssHostClasses(): string[] {
    return [
      this.statusClass(),
      this.sizeClass(),
      this.rootClass()
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
