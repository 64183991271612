import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Joove } from "../../@framework/core/joove";
import { first } from "rxjs/operators";
import { ContextService } from '../services/context.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private contextService: ContextService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    let newReq = req.clone({});

    if (window._context?.isModal) {
      newReq = req.clone({
        params: (req.params ? req.params : new HttpParams())
          .set('isModal', window._context?.isModal)
      });
    }

    return next.handle(newReq);

    //if (req.method !== "GET" || req.url.indexOf('_Context_') < 0) {
    //  return next.handle(req);
    //}

    //let context: any;
    //this.contextService.context$.pipe(first())
    //  .subscribe((data: Joove.IContext) => context = data);

    //if (context?.currentUser != null) { // TODO: find a more relaible way to check if the context has been initialized
    //  return of(context)
    //}

    //return next.handle(req);
  }
}
