import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsExceptDirective } from './directives/permissions-except.directive';
import { HasPermissionDirective, PermissionsOnlyDirective } from './directives/permissions-only.directive';
import { IPermissionService } from './interfaces/permission.interface';
import { PermissionService } from './services/permission.service';

const DATA_SERVICES = [
  { provide: IPermissionService, useClass: PermissionService },
];

const COMPONENTS = [
  PermissionsExceptDirective,
  PermissionsOnlyDirective,
  HasPermissionDirective
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule
  ],
  providers: [...DATA_SERVICES],
  exports: [...COMPONENTS]
})
export class SecurityModule { }
