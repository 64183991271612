import {NgModule} from '@angular/core';
import {zAppSearchModalComponent} from './searchmodal.component';
import {zAppDevModalModule} from "@framework/components/modal/modal.module";
import {zAppDevTextBoxModule} from "@framework/components/textbox/textbox.module";
import {zAppDevCheckBoxModule} from "@framework/components/checkbox/checkbox.module";
import {zAppDevTabContainerModule} from "@framework/components/tabcontainer/tabcontainer.module";
import {zAppDevGridModule} from "@framework/components/grid/grid.module";
import {RouterLink} from "@angular/router";
import {zAppDevButtonModule} from "@framework/components/button/button.module";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {zAppDevIconismModule} from "@framework/components/iconism/iconism.module";
import {zAppDevLabelModule} from "@framework/components/label/label.module";

@NgModule({
  imports: [
    zAppDevModalModule,
    zAppDevTextBoxModule,
    zAppDevCheckBoxModule,
    zAppDevTabContainerModule,
    zAppDevGridModule,
    zAppDevButtonModule,
    zAppDevIconismModule,
    CommonModule,
    FormsModule,
    RouterLink,
    TranslateModule,
    zAppDevLabelModule
  ],
  declarations: [zAppSearchModalComponent],
  exports: [zAppSearchModalComponent],
  providers: []
})
export class zAppDevBreadcrumbModule {
}
