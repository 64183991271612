<ng-container *ngIf="!data?.isSlide">
  <zapp-modal #FormModal [header]="formModalHeader" [body]="formModalBody" [footer]="formModalFooter" [style]="style">
    <ng-template #formModalHeader>
      <label zappLabel [size]="'lg'" [value]="title">
      </label>
      <zappIcon themeIcon="remove" style="float: right;" [size]="'lg'" (click)="closeModal()">
      </zappIcon>
    </ng-template>

    <ng-template #formModalBody>
      <router-outlet></router-outlet>
    </ng-template>

    <ng-template #formModalFooter>

    </ng-template>

  </zapp-modal>
</ng-container>

<ng-container *ngIf="data?.isSlide">
  <zapp-sidebar #FormSidebar [header]="formModalHeader" [footer]="formModalFooter" [style]="style" [slidePosition]="data.sidebarPosition">
    <ng-template #formModalHeader>
      <label zappLabel [size]="'lg'" [value]="title">
      </label>
      <zappIcon themeIcon="remove" style="float: right;" [size]="'lg'" (click)="closeModal()">
      </zappIcon>
    </ng-template>

    <router-outlet></router-outlet>

    <ng-template #formModalFooter>

    </ng-template>

  </zapp-sidebar>
</ng-container>

