import { Injectable, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as _moment from 'moment';
import { ContextService } from './context.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Joove } from '../../@framework/core/joove';

const moment = _moment;

export const TOKEN_NAME = `${environment.appId}_token`;
export const EXPIRES_IN = `${environment.appId}_expiresIn`;

@Injectable({
  providedIn: 'root'
})

export class AuthService implements OnDestroy {

  @Output() getLoggedIn: EventEmitter<any> = new EventEmitter();

  protected destroy$: Subject<void> = new Subject<void>();
  private context: Joove.IContext;

  constructor(private _httpClient: HttpClient, private contextService: ContextService) {
    this.contextService.context$.pipe(takeUntil(this.destroy$)).subscribe((context) => {
      this.context = context;
    });
  }


  signIn(username: string, password: string) {
    //this.consumeBackEnd(username, password).subscribe(
    //  (data) => {
    //    console.log(data);
    //    this.setSession(data);
    //    this.getLoggedIn.emit(true);
    //  },
    //);
  }

  consumeBackEnd(username: string, password: string) {
    return this._httpClient.post(`https://localhost:44348/OAuth/Token`, { username, password })
      .pipe();
  }

  public setSession(authResult: AuthResult) {
    const expiresAt = moment(authResult.expiresIn);
    // localStorage.setItem('applicationUser', JSON.stringify(authResult.applicationUser));
    localStorage.setItem(TOKEN_NAME, JSON.stringify(authResult.idToken).replace(/"/g, ""));
    localStorage.setItem(EXPIRES_IN, JSON.stringify(expiresAt.valueOf()));
  }

  logout() {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(EXPIRES_IN);
    localStorage.removeItem('applicationUser');
    this.getLoggedIn.emit(false);
  }

  public isLoggedIn() {
    if (!environment.windowsAuthentication) {
      return !this.hasTokenExpired();
    }

    const userName = this.context?.currentUser?.UserName;
    return userName != null && userName.trim().length > 0;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  private getExpiration() {
    const expiration = localStorage.getItem(EXPIRES_IN);
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  private hasTokenExpired() {
    if (!environment.windowsAuthentication) {
      const exp = this.getExpiration();
      return !exp.isValid() || moment(new Date()).isAfter(this.getExpiration());
    }

    return false;
  }

  getToken(): string {
    var token = localStorage.getItem(TOKEN_NAME);

    if (token != null && this.hasTokenExpired()) {
      this.logout();
      return null;
    }

    return token;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface AuthResult {
  expiresIn: string;
  idToken: string;
}
