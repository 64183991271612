import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevLabelComponent } from './label.component';


@NgModule({
  declarations: [zAppDevLabelComponent],
  imports: [
    CommonModule
  ],
  exports: [zAppDevLabelComponent]
})
export class zAppDevLabelModule {
  static forRoot(): ModuleWithProviders<zAppDevLabelModule> {
    return {
      ngModule: zAppDevLabelModule,
      providers: [ ],
    };
  }
}
