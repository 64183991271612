
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SignalRService } from 'src/app/@framework/signalr/signalr.service';
import { environment } from 'src/environments/environment';
import * as Domain from 'src/app/@core/domain';
import { System } from '@framework/core/clms.fw.dotnet';

@Injectable({
  providedIn: 'root'
})
export class EventsService extends SignalRService {
  
    SignIn$ = new Subject<any>();
SignOut$ = new Subject<any>();
ApplicationStart$ = new Subject<any>();
ApplicationEnd$ = new Subject<any>();
ApplicationError$ = new Subject<any>();
SessionStart$ = new Subject<any>();
OnInstanceSave$ = new Subject<any>();
FileDownload$ = new Subject<any>();
ExternalUserCreating$ = new Subject<any>();
UserAuthenticating$ = new Subject<any>();


  constructor() {
    super();
    this.buildConnection(environment.hubUrl);
    this.connect();
    this.registerOnServerEvents();
  }

  private registerOnServerEvents(): void {
    super.registerDefaultServerEvents();

    
    
  }
}

