@if (!forceRefreshing) {
<p-table #dt1 *ngIf="!hidden" [value]="_model" [paginatorPosition]="paginatorPosition" [paginator]="paginator"
         [alwaysShowPaginator]="allowAddRows" [rows]="showRowsPerPage" [showCurrentPageReport]="true"
         currentPageReportTemplate="{{ 'RES_DATALIST_Info' | translate: { START: first + firstNumberZeroBasedFix, END: last, TOTAL: totalRecords } }}"
         (onPage)="onPage($event)"
         [styleClass]="alternateRowColor ? 'p-datatable-striped' : ''" [rowHover]="hoverEffect" [rowTrackBy]="trackByFunction"
         [globalFilterFields]="globalFilterFields" [(first)]="first">

  <ng-template *ngIf="globalFilterFields != null && globalFilterFields.length > 0" pTemplate="caption">
    <div class="flex p-justify-between">
      <!--<button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>-->
      <div></div>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
          [placeholder]="'RES_DATALIST_Search' | translate" />
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <ng-container *ngTemplateOutlet="headerTpl"></ng-container>
  </ng-template>

  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <ng-container [ngTemplateOutlet]="bodyTpl" [ngTemplateOutletContext]="{ $implicit: item, rowIndex: index }">
    </ng-container>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <ng-container *ngTemplateOutlet="paginatorleftTpl"></ng-container>
    <zapp-button [zappCfconditionalFormatting]="addConditionals" *ngIf="allowAddRows" [disabled]="disabled"
                 [type]="'Button'" [size]="'sm'" (buttonClicked)="add()" class="zapp-additemlabel">
      <zappIcon themeIcon="plus" [size]="'sm'" [circularBackground]="false">
      </zappIcon>
    </zapp-button>
  </ng-template>

  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="footerTpl"></ng-container>
    <zapp-button [zappCfconditionalFormatting]="addConditionals" *ngIf="allowAddRows && !paginator"
                 [disabled]="disabled" [type]="'Button'" [size]="'sm'" (buttonClicked)="add()">
      <zappIcon themeIcon="plus" [circularBackground]="false"></zappIcon>
    </zapp-button>
  </ng-template>

  @if (showTotalRecordsNumber && !paginator) {
  <ng-template pTemplate="summary">
    <span>{{ 'RES_WEBFORM_PAGER_TotalRecords' | translate }}: {{totalRecords}}</span>
  </ng-template>
  }

  <ng-template pTemplate="emptymessage" *ngIf="showNoRecordsMessage">
    <tr>
      <td colspan="100" class="zapp-noRecordsCell">{{ noRecordsResource | translate }}</td>
    </tr>
  </ng-template>
</p-table>
}
