import { Injectable } from '@angular/core';
import { transformStyleToObject } from '@framework/common';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../../@core/services/auth.service';
import { PermissionService } from '../../security/services/permission.service';
import { zAppDevMenuItem } from './menu-item';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import { environment } from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs/operators";
import {lastValueFrom} from "rxjs";
import {UserProfileSettingsService} from "@services/userprofilesettings.service";
import { Router } from '@angular/router';


@Injectable()
export class MenuInternalService {

  iconOptions: ThemeOptions.IconismThemeOptions;

  // menu -> route -> count
  // this is used to store the most used menu items in memory
  // this object should always be in sync with the profile settings
  private mostUsedItems: Map<string, Map<string, number>> = new Map();

  // menu -> routes set
  // this is used to store the favorite menu items in memory
  // this object should always be in sync with the profile settings
  private favoriteItems: Map<string, Set<string>> = new Map();

  constructor(
    protected readonly permissionsService: PermissionService,
    protected readonly authService: AuthService,
    protected readonly translateService: TranslateService,
    private readonly userProfileSettings: UserProfileSettingsService,
    protected router: Router,
    private themeService: IThemeSevice) {

    this.iconOptions = this.themeService.getIconismThemeOptions();

  }

  prepareItem(item: zAppDevMenuItem, menu: string): MenuItem {

    const hasAccess = this.hasAccess(item);
    const headerClass = item.isHeader ? ' header' : '';

    let icon = this.iconOptions.Icons[item.icon];
    let menuItemWithImageClass = '';
    let iconStyle = undefined;
    if (item.image != null && item.image.length > 0) {
      icon = 'pi pi-plus';
      menuItemWithImageClass = ' image-menu-item';
      iconStyle = {
        'background-image': `url('${environment.baseUrl}/${item.image}')`.replace('//', '/'),
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center'
      }
    }

    const hasNavigationExtras = item.navigationExtras != null;

    return {
      styleClass: item.class + headerClass + menuItemWithImageClass,
      style: item.style ? transformStyleToObject(item.style) : '',
      label: item.label ? item.label() : '',
      icon,
      title: item.tooltip ? item.tooltip() : '',
      items: item.children?.map((child) => this.prepareItem(child, menu)),
      routerLink: hasNavigationExtras ? undefined : item.route,
      url: item.url,
      visible: hasAccess || item.authorization.action == 'DISABLE',
      disabled: !hasAccess && item.authorization.action == 'DISABLE',
      command: hasNavigationExtras ? () => {
        this.router.navigate([item.route], item.navigationExtras);
      } : item.onClick,
      iconStyle
    }
  }

  /**
   * Increments the click count of the given menu item by 1.
   */
  async incrementMenuItemClickCount(route: string, menu: string) {
    if (!route) {
      return;
    }
    const items = this.getMostUsedMenuItems(menu);
    const currentCount = items.get(route) || 0;
    items.set(route, currentCount + 1);
    await this.setMostUsedMenuItems(items, menu);
  }

  /**
   * Removes the given menu item from the list of most used items.
   * Basically makes its use count 0 by removing it.
   */
  async removeMenuItemClickCount(route: string, menu: string) {
    const items = this.getMostUsedMenuItems(menu);
    items.delete(route);
    await this.setMostUsedMenuItems(items, menu);
  }

  /**
   * Prefetch the most used and favorite items from a menu. This method must be called
   * before anything else. Ideally as part of the constructor which is not possible here.
   */
  async fetchMenu(menu: string) {
    this.fetchMostUsed(menu);
    this.fetchFavorites(menu);
  }

  private async fetchMostUsed(menu:string) {
    const mostUsed = await this.userProfileSettings.getProfileSetting(`MenuMostUsed_${menu}`);
    const mostUsedMap = new Map<string, number>();
    if (!mostUsed) {
      this.mostUsedItems.set(menu, mostUsedMap);
      return;
    }
    const parsedObject = JSON.parse(mostUsed);
    Object.keys(parsedObject).forEach(key => {
      mostUsedMap.set(key, parsedObject[key]);
    });
    this.mostUsedItems.set(menu, mostUsedMap);
  }

  private async fetchFavorites(menu:string) {
    const favorites = await this.userProfileSettings.getProfileSetting(`MenuFavorites_${menu}`);
    const favoritesSet = new Set<string>();
    if (!favorites) {
      this.favoriteItems.set(menu, favoritesSet);
      return;
    }
    const parsedFavorites = JSON.parse(favorites);
    parsedFavorites.forEach((favorite: string) => {
      favoritesSet.add(favorite);
    });
    this.favoriteItems.set(menu, favoritesSet);
  }

  /**
   * Returns the items that the user has marked as favorites.
   */
  getFavorites(menu: string): Set<string> {
    return this.favoriteItems.get(menu);
  }

  /**
   * Add a route to the list of favorites.
   */
  addFavorite(menu: string, route: string) {
    // Add the favorite to the in-memory list
    this.favoriteItems.get(menu).add(route);
    // Store the favorite in the profile settings
    this.userProfileSettings.setProfileSetting(
      `MenuFavorites_${menu}`,
      JSON.stringify(Array.from(this.favoriteItems.get(menu)))
    );
  }

  /**
   * Remove a route from the list of favorites.
   */
  removeFavorite(menu: string, route: string) {
    // Remove the favorite from the in-memory list
    this.favoriteItems.get(menu).delete(route);
    // Store the updated list in the profile settings
    this.userProfileSettings.setProfileSetting(
      `MenuFavorites_${menu}`,
      JSON.stringify(Array.from(this.favoriteItems.get(menu)))
    );
  }

  /**
   * Returns the list of menu items that are most used along with their counts.
   */
  getMostUsedMenuItems(menu: string): Map<string, number> {
    return this.mostUsedItems.get(menu);
  }

  /**
   * Sets the list of most used menu items along with their counts.
   */
  async setMostUsedMenuItems(items: Map<string, number>, menu: string) {
    // Set in memory
    this.mostUsedItems.set(menu, items);
    // Store in the profile settings
    const objectToStore = {};
    items.forEach((value, key) => {
      objectToStore[key] = value;
    });
    const stringifiedObject = JSON.stringify(objectToStore);
    await this.userProfileSettings.setProfileSetting(`MenuMostUsed_${menu}`, stringifiedObject);
  }

  prepareItems(items: zAppDevMenuItem[], menu: string): MenuItem[] {
    return items.map((item) => this.prepareItem(item, menu));
  }

  private hasAccess(item: zAppDevMenuItem): boolean {
    let visible = false;

    if (this.authService.isLoggedOut()) {
      visible = item.authorization.allowAnonymous;
    }

    if (this.authService.isLoggedIn()) {
      if (item.authorization.allowAuthenticated) {
        visible = true;
      } else {
        visible = this.permissionsService.hasPermission(item.authorization.permissions);
      }
    }

    if (item.children != null && item.children.length > 0) {
      const childs = item.children.map((child) => this.hasAccess(child));

      if (visible) {
        visible = childs.every(child => child == false);
      } else {
        visible = childs.some(child => child == true);
      }
    }

    return visible;
  }
}
