import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevTextBoxComponent } from './textbox.component';
import { IConfig, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [zAppDevTextBoxComponent],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [zAppDevTextBoxComponent]
})
export class zAppDevTextBoxModule { }
