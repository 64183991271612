import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { CF_COMPONENT } from "../../rule-engine/directives/condition-formatting.directive";
import { zAppDevBaseComponent } from "../basecomponent/base.component";
import { zAppDevComponentSize } from "../component-size";
import { zAppDevComponentStatus } from "../component-status";
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from "../../theme/interfaces/theme.interface";
import { environment } from "src/environments/environment";

@Component({
  selector: 'zapp-button',
  templateUrl: './button.component.html',
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevButtonComponent }]
})
export class zAppDevButtonComponent extends zAppDevBaseComponent {

  protected options: ThemeOptions.ButtonThemeOptions;

  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input() size: zAppDevComponentSize = '';

  @Input() status: zAppDevComponentStatus = 'global';

  @Input() class: string = '';

  @Input() icon: string = null;

  @Input() type: string = "Button";

  @Input() btnDisabled: boolean = false;

  @Input()
  set imageUrl(value: string) {
    this._imageUrl = `${environment.baseUrl}/${value}`;
  }
  _imageUrl: string;

  // override to apply style classes on the root element
  @HostBinding('class')
  get hostClasses(): string {
    return this.hostClassesArray.concat(this.styleClassesArray).join(' ');
  }

  @HostBinding('class.hidden')
  get isHidden() {
    return this.hidden === true;
  }

  constructor(protected themeservice: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
    this.options = this.themeservice.getButtonThemeOptions();
  }

  hidden: boolean = false;

  disabled: boolean = false;

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {

  }

  isButton() {
    return this.type == "Button";
  }

  isLink() {
    return this.type == "Link";
  }

  isImage() {
    return this.type == "Image";
  }

  onClick(event) {
    this.buttonClicked.emit(event);
  }
}
