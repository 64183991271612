import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IValidationModel } from '@framework/interfaces/validation-item.interface';

export interface ICodeHelper {
  translate: TranslateService;
}


@Injectable({
  providedIn: 'root'
})
export class CodeHelper implements ICodeHelper {

  public validationModel: IValidationModel;

  constructor(public translate: TranslateService) { }
}
