import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,

  HostBinding,
  ElementRef
} from '@angular/core';


import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import { zAppDevComponentStatus } from '../component-status';
import { zAppDevBaseComponent } from '@framework/components/basecomponent/base.component';
import { zAppDevComponentSize } from '../component-size';
import { transformStyleToObject } from '../../common/utils';

@Component({
  selector: 'zapp-modal',
  templateUrl: './modal.component.html',
})
export class ZAppDevModalComponent extends zAppDevBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  themeOptions: ThemeOptions.ModalThemeOptions;

  @Input() status: zAppDevComponentStatus = 'global';

  @Input() size: zAppDevComponentSize = '';

  @Input() class: string = '';

  @Input() contentStyle = '';

  @Input() draggable: boolean = true;

  dialogClass: string;

  @Input('header')
  headerTpl: TemplateRef<any>;

  @Input('body')
  bodyTpl: TemplateRef<any>;

   @Input('footer')
  footerTpl: TemplateRef<any>;

  options: ThemeOptions.ModalThemeOptions;
  display: boolean = false;

  get _contentStyle(): any {
    return transformStyleToObject(this.contentStyle);
  }

  constructor(private themeService: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
    this.themeOptions = this.themeService.getModalThemeOptions();

  }

  ngOnInit() {
    super.ngOnInit();
    this.updateClass();
  }
  
  ngAfterViewInit() {

  }

  ngOnDestroy() {

  }

  public showPickListDialog(showCallback: () => void) {
    this.display = true;
    showCallback();
  }

  public showDialog() {
    this.display = true;
  }

  public hideDialog() {
    this.display = false;
  }

  statusClass() {
    const extraClasses = this.themeOptions[this.variation]?.Classes?.Roles[this.status];
    return `zapp-modal zapp-p-dialog-header zapp-p-dialog-content zapp-p-dialog-footer ${extraClasses}`;
  }

  private updateClass() {
    if (this.themeOptions != null) {
      this.dialogClass = [
        this.class,
        this.statusClass(),
      ].join(' ');
    }
  }
}
