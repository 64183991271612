@if (_innerHtmlValue != null) {
  <span [innerHTML]="_innerHtmlValue"></span>
} @else {
  <ng-container *ngIf="_value != null && !hidden">
    {{_value}}
  </ng-container>

  <ng-container *ngIf="_value == null && !hidden">
    <ng-content></ng-content>
  </ng-container>
}
