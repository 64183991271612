import { valueIsObject } from './utils';

export const cloneObject = (originalObject: any): any => {
  if (originalObject == null) return null;

  if (valueIsObject(originalObject) === false) return originalObject;

  // Based on:
  // https://github.com/cronvel/tree-kit/blob/master/lib/clone.js
  // (MIT licence)

  // First create an empty object with
  // same prototype of our original source
  let propertyIndex: number;
  let descriptor: PropertyDescriptor;
  let keys: string[];
  let current: { source; target };
  let nextSource: object | typeof undefined[];
  let indexOf: number;
  const copies = [{ source: originalObject, target: Object.create(Object.getPrototypeOf(originalObject)) }];
  const clonedObject = copies[0].target;
  const sourceReferences = [originalObject];
  const targetReferences = [clonedObject];

  // First in, first out
  // tslint:disable-next-line
  while ((current = copies.shift()))
  {
      keys = Object.getOwnPropertyNames(current.source);

      for (propertyIndex = 0; propertyIndex < keys.length; propertyIndex++) {
          // Save the source's descriptor
          descriptor = Object.getOwnPropertyDescriptor(current.source, keys[propertyIndex]);

          if (!descriptor.value || valueIsObject(descriptor.value) === false) {
              Object.defineProperty(current.target, keys[propertyIndex], descriptor);
              continue;
          }

          nextSource = descriptor.value;
          descriptor.value = Array
              .isArray(nextSource)
              ? []
              : Object.create(Object.getPrototypeOf(nextSource));

          indexOf = sourceReferences.indexOf(nextSource);

          if (indexOf !== -1) {
              // The source is already referenced, just assign reference
              descriptor.value = targetReferences[indexOf];
              Object.defineProperty(current.target, keys[propertyIndex], descriptor);
              continue;
          }

          sourceReferences.push(nextSource);
          targetReferences.push(descriptor.value);

          Object.defineProperty(current.target, keys[propertyIndex], descriptor);

          copies.push({ source: nextSource, target: descriptor.value });
      }
  }

  return clonedObject;
}
