import { HttpHeaders } from '@angular/common/http';

export const zAppDevPostActionOptions : any = {
  observe: 'response',
  headers: new HttpHeaders({
    'Content-Type': 'application/zappdev',
    'X-Requested-With': 'XMLHttpRequest',
  })
}

export const zAppDevGetActionOptions  : any = {
  observe: 'response',
  headers: new HttpHeaders({
    'X-Requested-With': 'XMLHttpRequest',
  })
}
