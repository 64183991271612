import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { zAppDevGridComponent } from './grid.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { zAppDevIconismModule } from '../iconism/iconism.module';
import { zAppDevButtonModule } from '../button/button.module';
import { RuleEngineModule } from '../../rule-engine/rule-engine.module';
import { TranslateModule } from '@ngx-translate/core';


const DATATABLE_COMPONENTS = [zAppDevGridComponent];
const DATATABLE_PROVIDERS = [];


@NgModule({
  imports: [
    CommonModule,
    // TODO: probably we don't need FormsModule in SharedModule
    FormsModule,
    RouterModule,
    TranslateModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    RuleEngineModule,
    zAppDevIconismModule,
    zAppDevButtonModule,
  ],
  declarations: [...DATATABLE_COMPONENTS],
  exports: [...DATATABLE_COMPONENTS],
})
export class zAppDevGridModule {
  static forRoot(): ModuleWithProviders<zAppDevGridModule> {
    return {
      ngModule: zAppDevGridModule,
      providers: [
        ...DATATABLE_PROVIDERS
      ],
    };
  }
}
