import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevIconismComponent } from './iconism.component';



@NgModule({
  declarations: [zAppDevIconismComponent],
  imports: [
    CommonModule
  ],
  exports: [zAppDevIconismComponent]
})
export class zAppDevIconismModule {
  static forRoot(): ModuleWithProviders<zAppDevIconismModule> {
    return {
      ngModule: zAppDevIconismModule,
      providers: [ ],
    };
  }
}
