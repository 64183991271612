export const environment = {
  production: true,
  appUrl: '/api/',
  hubUrl: '/hub',
  i18nUrl: '',
  baseUrl: '/',
  appId: 'HomeMedicalCare',
  defaultPage: 'HomePage',
  windowsAuthentication: false,
  semanticVersion: '0.0.0'
};