export class FriendlyMessageDTO {
    Title: string; 
    OriginalStackTrace?: string;
    OriginalExceptionMessage: string;
    ExceptionType?: string;
    Entries?: Array<FriendlyMessageEntryDTO>;
}

export class FriendlyMessage {
    constructor(dto: FriendlyMessageDTO) {
        this.dto = dto;

        if (this.dto.Entries == null) return;
    }

    dto: FriendlyMessageDTO;
}

export class FriendlyMessageEntryDTO {
    // As deserialized
    AppdevIdentifier: string;
    AppdevSemantic: string;

    // Filled client side
    icon: string;
    friendlyMessage: string;
}